<template>
  <div>
    <form
      role="form"
      id="myForm"
      name="myForm"
      ref="myForm"
      enctype="multipart/form-data"
      action
      method
    >
      <div class="recharge-account">
        <div class="game_select">
          <label class="label_name">儲值帳號：</label>
          <span>{{ userInfo.account }}</span>
        </div>
        <div class="recharge-game clearfix">
          <div class="label fl">
            <span class="label_name">儲值遊戲：</span>
          </div>
          <div class="game-content fl">
            <game-swiper
              :userRegisteredGamesList="userRegisteredGamesList"
              :curId="curId"
              :gameId="gameId"
              @getGameStoreSwiperIndex="getGameStoreSwiperIndex"
            ></game-swiper>
          </div>
        </div>
        <div class="game-area">
          <span class="label_name">遊戲伺服器：</span>
          <template>
            <el-select
              v-model="form.severInfo"
              placeholder="请选择"
              @change="handleChangedServer($event)"
            >
              <el-option
                v-for="item in serverNameList"
                :key="item.server_id"
                :label="item.server_name"
                :value="item.server_id"
              ></el-option>
            </el-select>
          </template>

          <span class="label_name">角色名：</span>
          <template>
            <el-select v-model="form.roleInfo" placeholder="请选择">
              <el-option
                v-for="item in roleNameList"
                :key="item.role_id"
                :label="item.role_name"
                :value="item.role_id"
              ></el-option>
            </el-select>
          </template>
        </div>
        <div class="recharge-money clearfix">
          <div class="label fl">
            <span class="label_name">儲值商品：</span>
          </div>
          <div class="game-content fl">
            <ul class="clearfix czmoney">
              <li
                class="xzsp"
                v-for="(item, index) in commodityList"
                :key="index"
                :class="{ active: commodityId == item.id }"
                @click="getCommodityId(item.id, item.name, item.price)"
              >
                <img class="commodityImg" :src="item.icon" />
                <p class="txt2">{{ item.name }}</p>
              </li>
            </ul>
          </div>
        </div>
        <div class="method-money clearfix">
          <div class="label" style="margin-top: 5px">
            <span class="label_name">儲值方式：</span>
          </div>
          <div class="method-content">
            <ul class="clearfix paylist">
              <li v-for="(item, index) in payList" :key="index">
                <template>
                  <el-radio-group v-model="radio">
                    <el-radio :label="item.payid" @change="getPlayId(item.payid)">
                      <label for="card1" style="margin-left: 9px">
                        <span class="img">
                          <img
                            :src="item.icon"
                            :class="{
                              imgWidth: item.payid == 32 || item.payid == 33,
                            }"
                          />
                        </span>
                        <span style="margin-left: 9px" class="name">
                          {{ item.name }}
                        </span>
                      </label>
                    </el-radio>
                  </el-radio-group>
                </template>
              </li>
            </ul>
          </div>
        </div>
        <div class="total-amount total-product">
          <span class="fzjb1 label_name">儲值商品：</span>
          {{ commodityName }}
        </div>
        <div class="total-amount">
          <span class="fzjb label_name">應付金額：</span>
          {{ commodityprice }}美元
        </div>
        <a href="javascript:;" class="pay1" @click="handleClickPay($event)">立即支付</a>
      </div>
    </form>
    <loading :options="options"></loading>
  </div>
</template>

<script>
import "@/pages/pc/assets/styles/recharge.css";
import GameSwiper from "./GameSwiper";
import Loading from "./Loading";
export default {
  name: "GameStore",
  props: {
    curId: Number,
  },
  components: {
    GameSwiper,
    Loading,
  },
  data: function () {
    return {
      uuid: 0,
      game_id: 0,
      commodityId: 0,
      serverId: 0,
      payId: 0,
      roleId: 0,
      appId: 0,
      firstIndex: 0,
      serverNameList: [],
      userRegisteredGamesList: [],
      s_value: "",
      roleNameList: [],
      r_value: "",
      form: {
        severInfo: "",
        roleInfo: "",
      },
      commodityList: [],
      payList: [],
      commodityprice: "",
      commodityName: "",
      radio: "1",
      options: {
        isLoading: true,
      },
      userInfo: {},
    };
  },
  computed: {
    gameId: function () {
      return this.game_id;
    },
  },
  mounted() {
    this.getUserRegisteredGamesList();
    if (localStorage.getItem("userInfo")) {
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    }
  },
  methods: {
    getUserRegisteredGamesList: function () {
      let datas = {};
      this.options.isLoading = true;
      this.getHttp(this, datas, "/front/user/games", function (obj, data) {
        if (data.status == 2000) {
          let d = data.data;
          obj.userRegisteredGamesList = d.games_list;
          if (d.games_list.length == 0) {
            obj.options.isLoading = false;
            obj.$toast("暂无游戏！");
          } else {
            obj.game_id = d.games_list[0].id;
            obj.uuid = d.games_list[0].uuid;
            obj.appId = d.games_list[0].appid;
            obj.getServerRoleInfo(obj.gameId, obj.uuid, obj.firstIndex);
            obj.getGamescommodityPay(obj.gameId, obj.firstIndex);
          }
        }
        obj.options.isLoading = false;
      });
    },
    getGameStoreSwiperIndex: function (gameId, uuid, index) {
      this.options.isLoading = true;
      this.game_id = gameId;
      this.uuid = uuid;
      this.firstIndex = index;
      this.getServerRoleInfo(gameId, uuid, index);
      this.getGamescommodityPay(gameId, index);
      this.form.severInfo = "";
      this.form.roleInfo = "";
      this.serverNameList = "";
      this.roleNameList = "";
    },
    getServerRoleInfo: function (gameId, uuid) {
      let datas = {
        game_id: gameId,
        appid: this.appId,
        uuid: uuid,
      };
      this.getHttp(this, datas, "/front/server/role", function (obj, data) {
        if (data.status == 2000) {
          let d = data.data;
          obj.serverNameList = d;
          if (obj.serverNameList.length == 0) {
            obj.options.isLoading = false;
            obj.$toast("暂无该游戏下的区服角色数据！");
          } else {
            obj.form.severInfo = obj.serverNameList[0].server_id;
            obj.handleChangedServer(obj.form.severInfo);
          }
        }
        obj.options.isLoading = false;
      });
      this.options.isLoading = false;
    },
    getGamescommodityPay: function (gameId, index) {
      let datas = {
        game_id: gameId,
      };
      this.getHttp(this, datas, "/front/commodity/pay", function (obj, data) {
        if (data.status == 2000) {
          let d = data.data;
          obj.commodityList = d.commodity_list;
          if (obj.commodityList.length == 0) {
            obj.options.isLoading = false;
            obj.$toast("暂无该游戏下的商品数据！");
          } else {
            obj.payList = d.pay_list;
            obj.radio = d.pay_list[0].payid;
            obj.payId = d.pay_list[0].payid;
            obj.commodityId = d.commodity_list[0].id;
            obj.commodityName = d.commodity_list[index].name;
            obj.commodityprice = d.commodity_list[index].price;
          }
        }
        obj.options.isLoading = false;
      });
      this.options.isLoading = false;
    },
    handleChangedServer: function (s_id) {
      this.serverId = s_id;
      this.form.roleInfo = "";
      if (s_id !== null && s_id !== "" && s_id !== undefined) {
        for (var item of this.serverNameList) {
          if (item.server_id == s_id) {
            this.roleNameList = item.role_info;
            this.roleId = item.role_info[0].role_id;
            this.form.roleInfo = this.roleId;
          }
        }
      }
    },
    getCommodityId: function (id, name, price) {
      this.commodityId = id;
      this.commodityName = name;
      this.commodityprice = price;
    },
    getPlayId: function (id) {
      this.payId = id;
    },
    handleClickPay: function (event) {
      event.preventDefault();

      if (!this.gameId) {
        this.$toast("請選擇儲值遊戲!");
        return false;
      }
      if (!this.serverId) {
        this.$toast("請選擇服務器!");
        return false;
      }
      if (!this.roleId) {
        this.$toast("請選擇角色名!");
        return false;
      }
      if (!this.commodityId) {
        this.$toast("請選擇儲值商品!");
        return false;
      }
      if (!this.payId) {
        this.$toast("請選擇支付方式!");
        return false;
      }

      // 正式服encodeUrl:https%3A%2F%2Fluckystar.gamemorefun.com%2F%23%2Fpayment
      // 测试服http%3A%2F%2F192.168.93.233%3A9090%2F%23%2Fpayment

      let datas = {
        game_id: this.gameId,
        uuid: this.uuid,
        commodity_id: this.commodityId,
        payid: this.payId,
        server_id: this.serverId,
        role_id: this.roleId,
        redirect_url: "https%3A%2F%2Fluckystar.gamemorefun.com%2F%23%2Fpayment",
      };
      this.postHttp(this, datas, "/front/pay/recharge", function (obj, data) {
        if (data.status == 2000) {
          let d = data.data;
          let toPayLink = d.jump_url_info.jump_uri;
          let params = d.jump_url_info.params;
          let orderId = d.jump_url_info.params.order_id;
          localStorage.setItem("orderId", orderId);
          let newUrl =
            toPayLink +
            "?token=" +
            params.token +
            "&app_id=" +
            params.app_id +
            "&uuid=" +
            params.uuid +
            "&product_id=" +
            params.product_id +
            "&order_id=" +
            params.order_id +
            "&device_type=" +
            params.device_type +
            "&extInfo=" +
            params.extInfo +
            "&ServerId=" +
            params.ServerId +
            "&RoleId=" +
            params.RoleId +
            "&gateway=" +
            params.gateway +
            "&redirect_url=" +
            params.redirect_url;

          alert("即將跳轉支付頁面，請確認!");
          let gateway = d.jump_url_info.params.gateway;
          if (gateway == "Mcoin") {
            document.myForm.method = "GET";
            document.myForm.action = newUrl;
            window.location.href = newUrl;
          } else {
            document.myForm.method = "POST";
            document.myForm.action = newUrl;
            obj.$refs["myForm"].submit();
          }
        }
        obj.options.isLoading = false;
      });
    },
    PaySuccessCallback: function (order_id) {
      let datas = {
        order_id: order_id,
      };
      this.postHttp(this, datas, "/front/pay/complete", function (obj, data) {
        if (data.status == 2000) {
          obj.$toast(data.message);
        } else {
          obj.$toast(data.message);
        }
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
form >>> .el-radio__input.is-checked .el-radio__inner {
    border-color: #ff7a07;
    background: #ff7a07;
}

form >>> .el-radio__input {
    margin-top: -17px;
}

form >>> .el-radio__inner:hover {
    border-color: #ff7a07;
}

.label_name {
    display: inline-block;
    width: 135px !important;
}

.el-select {
    margin-left: 0px;
}

.active {
    border: 4px solid #ff7a07;
    box-sizing: border-box;
}

.active::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    width: 26px;
    height: 26px;
    background: url('../assets/imgs/selected.png') no-repeat;
    background-size: 100% 100%;
}

.czmoney {
    margin-top: 2px;
  .commodityImg {
    width: 64%;
  }
}

iframe {
    border: none;
}

.game-area {
    text-align: left;
    padding-left: 14px;

    span {
        vertical-align: middle;

        &:nth-of-type(2) {
            margin-left: 30px;
        }
    }
}

.imgWidth {
    width: 28%;
}

.total-amount {
    text-align: left;
    padding-left: 14px;
}

.game_select {
    padding-left: 14px;
    margin-bottom: 46px;
    text-align: left;

    label {
        width: 100%;
        color: #5d5d5d;
        margin-bottom: 42px;

        &:nth-of-type(1) {
        }

        &:nth-of-type(2) {
            margin-left: 50px;
        }
    }

    span {
        font-size: 22.35px;

        &:nth-of-type(1) {
            color: #222222;
        }

        &:nth-of-type(2) {
            color: #ff7a07;
        }
    }
}

.content {
    width: 783px;
    height: 275px;
    padding: 0 35px 0 14px;
    margin-top: 5px;
    background: #fff;

    .hot_img {
        margin-top: 15px;

        .gift_info {
            margin-top: 9px;

            span {
                font-size: 19px;

                &:nth-of-type(1) {
                    color: #202020;
                }

                &:nth-of-type(2) {
                    color: #8d8c8c;
                }
            }
        }
    }

    .hot_download {
        width: 430px;
        margin-top: 20px;
        margin-left: 37px;

        .hot_top {
            .left {
                width: 100%;
            }

            h2 {
                color: #191919;
                font-size: 29px;
                font-weight: 500;
            }

            .span {
                margin-top: 4px;
                color: #999999;
                font-size: 23px;
            }
        }

        .hot_center {
            font-size: 21px;
            color: #202020;
            margin-top: 37px;
        }

        .hot_bottom {
            margin-top: 17px;

            .btn_get {
                display: inline-block;
                width: 198px;
                height: 49px;
                line-height: 49px;
                text-align: center;
                color: #ff7a07;
                font-size: 27.08px;
                border: 2px solid #f5854e;
                border-radius: 5px;
            }
        }
    }
}
</style>
